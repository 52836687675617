import React from 'react'
import { Fade } from "react-slideshow-image";
// Importing image
import img1 from '../../Assets/Images/IMG_A1.PNG';
import img2 from '../../Assets/Images/IMG_A2.PNG';
import img3 from '../../Assets/Images/IMG_A3.PNG';
import img4 from '../../Assets/Images/IMG_A4.PNG';
// Importing style
import './PhonePreviewComponent.css';
import "react-slideshow-image/dist/styles.css";

function PhonePreviewComponent() {

    const properties = {
        duration: 3500,
        autoplay: true,
        transitionDuration: 500,
        arrows: false,
        infinite: true
    };

    const slideImages = [img1, img2, img3, img4];

    return (
        <div className="phone-preview-cnt">
            <div className="phone-preview-cnt_img-entity">
                <div className="slide-container">
                    <Fade {...properties}> 
                        {slideImages.map((each, index) => (
                            <div key={index} className="each-fade">
                                <img className="lazy phone-preview-cnt_img-content" src={each} alt="slider" />
                            </div>
                        ))}
                    </Fade>
                </div>
            </div>
        </div>
    )
}

export default PhonePreviewComponent
