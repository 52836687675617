import React from 'react'
// Importing style
import './SpinnerComponent.css';

function SpinnerComponent() {
    return (
        <div id="spinner-indicator-layout-cnt"></div>
    )
}

export default SpinnerComponent
