import React from 'react'
import PhonePreview from '../PhonePreviewComponent/PhonePreviewComponent';
// Importing style
import './StartComponent.css';
import '../../DefaultStyle.css';
import '../NavComponent/NavComponent.css';
// Importing image
import logo from '../../Assets/Logos/j.logo.gradient.png';

function StartComponent() {
    function openSampleProfile() {
        const sample_url = "https://jinxvb.com/shared?user=77l8BD5kOlMDeof8WqCDoF5zKYJ3&card=OuM4ionYGgviEGniwObz";
        window.open(sample_url, "_self");
    }

    return (
        <div className="start-main-cnt">
            <div className="grid center-grid">
                <img data-testid="start-comp_company-logo" id="logo-header" src={logo} alt="logo" />
                <PhonePreview />
                <h1 id="objective-header">Build a network you're proud of.</h1>

                <div className="start-main-cnt_smpl-btn-entity-cnt">
                    <button className="start-main-cnt_smpl-btn" type="button" onClick={openSampleProfile}>Sample Profile</button>
                </div>
            </div>

            
        </div>
    )
}

export default StartComponent
