import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
// Importing style
import './App.css';
// Importing component
import StartPage from './Components/StartComponent/StartComponent';
import DetailCard from './Components/DetailCardComponent/DetailCardComponent'; 
import ErrorPage from './Components/ErrorComponent/ErrorComponent';
import PrivacyPolicy from './Components/PrivacyPolicyComponent/PrivacyPolicy';
import Footer from './Components/FooterComponent/FooterComponent';

function App() {
  return (
    <main className="main page-container" role="main">
      <div className="main-content-wrapper">
        <Router>
          <Switch>
              <Route exact path="/" component={StartPage} />
              <Route exact path="/shared" component={DetailCard} />
              <Route exact path="/documents/privacy_policy" component={PrivacyPolicy} />
              <Route component={ErrorPage} />
          </Switch>
        </Router>
      </div>
      <Footer/>
    </main>
  );
}

export default App;
