import React from 'react'
// Importing style
import './ErrorComponent.css';

function ErrorComponent(props) {
    const { errorCode, errorMessage } = props;

    return (
        <div className="error-page-cnt">
            <h1 className="error-page-cnt_entity-header">Error {errorCode == null ? 404 : errorCode}</h1>
            <h3 className="error-page-cnt_entity-message">{errorMessage == null ? "The page you looking for doesn't exist." : errorMessage}</h3>
        </div>
    )
}

export default ErrorComponent
