import React, { useState, useCallback, memo } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
// Importing style
import '../DetailCardComponent/GeneralItemComponents/GeneralItemComponents.css';

const containerStyle = {
    width: '100%',
    height: '100%'
};

const options = {
   disableDefaultUI: true,
   zoomControl: true
};

const earthCenter = {
  lat: 0,
  lng: 0
}

function MapsComponent(props) {
  const { lat, lng } = props

  const [map, setMap] = useState(null);
  const [coordinateAddress, setCoordinateAddress] = useState(earthCenter);

  const onLoad = useCallback(function callback(map) {
    // const geocoder = new window.google.maps.Geocoder();
    // geocoder.geocode({address: address}, (results, status) => {
    //   if(status === "OK") {
    //     const location = results[0].geometry.location

    //     setFormattedAddress(results[0].formatted_address);
    //     setCoordinateAddress({lat:location.lat(), lng:location.lng()});

    //     map.setCenter(location);
    //     new window.google.maps.Marker({
    //       map: map,
    //       position: location
    //     });
    //     map.setZoom(16);
    //   }
    // });

    const location = { lat: lat, lng: lng }
    setCoordinateAddress({lat: lat, lng: lng})

    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    map.setCenter(location)
    new window.google.maps.Marker({
      map: map,
      position: location
    });
    
    setMap(map)
  }, [])
  
  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  return (
    <>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <div className="google-map-layout_entity-map">
          <GoogleMap 
            mapContainerStyle={containerStyle}
            center={earthCenter} 
            zoom={14}
            options={options}
            onLoad={onLoad} 
            onUnmount={onUnmount}>
          </GoogleMap>
        </div>
      </LoadScript>
      <div className="general-card-layout-info_entity-item-cont">
        <div className="general-card-layout-info_entity-item">
          <button className="general-card-layout-info_value general-round-rectangle-layout" onClick={function() {
            window.open(`https://maps.google.com?q=${coordinateAddress.lat},${coordinateAddress.lng}`);
          }}>Get Direction</button>
        </div>
      </div>
    </>
  )
}

export default memo(MapsComponent)
