import { useState, useEffect } from 'react'

export const useQueryCard = (location) => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();

    function processAPIURL(uid, cid) {
        return `${process.env.REACT_APP_API_BASE_URL}/card/api/read?user=${uid}&card=${cid}`
    }

    useEffect(() => {
        try {
            const queries = new URLSearchParams(location.search)
            // Get user and card param
            const user_id = queries.get("user");
            const card_id = queries.get("card");

            if(!(user_id && card_id)) {
                throw new Error('Unable to define query parameters.');
            }

            fetch(processAPIURL(user_id, card_id))
            .then(res => {
                if(!res.ok) throw new Error(res.statusText);
                else return res.json();
            }).then(data => {
                setData(data);
                setLoading(false);
            }).catch(err => {
                setError(err);
                setLoading(false);
            });
        } catch (e) {
            setError(e);
            setLoading(false);
        }
    },[]);

    return {error,loading,data};
}