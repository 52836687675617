import React, { useState } from 'react';
import './PrivacyPolicy.css';
import { Document, Page, pdfjs } from 'react-pdf';
import privacyPDF from '../../Assets/Documents/PRIVACY_NOTICE.pdf';
// Import my component
import NavComponent from '../NavComponent/NavComponent';

function PrivacyPolicy() {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
        <div>
            <NavComponent />
            <div className="reader-pdf-cnt center_cnt">
                <Document file={privacyPDF} options={{ workerSrc: "/pdf.worker.js" }} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} />
                </Document>
            </div>
            <div className="center_cnt" style={{width:"30%"}}>
                {/* <p>Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}</p> */}
                <button className="nav-bar_dynamic-open-app-btn" type="button" disabled={pageNumber <= 1} onClick={previousPage}>❮</button>
                <button className="nav-bar_dynamic-open-app-btn" type="button" disabled={pageNumber >= numPages} onClick={nextPage}>❯</button>
            </div>
        </div>
    )
}

export default PrivacyPolicy
