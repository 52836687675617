// Import lib component
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryCard } from '../../Hooks/useQueryCard';
import { Helmet } from 'react-helmet';
// Import my component
import NavComponent from '../NavComponent/NavComponent';
import { GeneralItemLabelComponent, GeneralItemLabelSublabelComponent, GeneralItemLinkComponent, GeneralImageItemLinkComponent } from './GeneralItemComponents/GeneralItemComponents';
import MapsComponent from '../MapsComponent/MapsComponent';
import ErrorComponent from '../ErrorComponent/ErrorComponent';
import SpinnerComponent from '../SpinnerComponent/SpinnerComponent';
// Importing style
import './DetailCardComponent.css'
import '../../App.css';
// Importing image
import defaultProfileImage from '../../Assets/Logos/profile.avatar.jpg';
import quoteIcon from '../../Assets/Images/quote.png';

import linkedin from '../../Assets/Socials/linkedin.png';
import github from '../../Assets/Socials/github.png';
import instagram from '../../Assets/Socials/instagram.png';
import twitter from '../../Assets/Socials/twitter.png';
import internet from '../../Assets/Socials/internet.png';

const defaultCardImagePath = '/static/media/j.image.default.background.cover.4d7b1cdb.jpg';

// function combineAddress(address) {
//     const { street_address, city, state, zipcode } = address;
//     const combineAddress = `${street_address} ${city}, ${state} ${zipcode}`;
//     // Due to empty space in address format, there is 3 space and 1 comma => which equal to length of 4
//     return combineAddress.length > 4 ? combineAddress : null;
// }

function switchSocialImage(type) {
    var finalImage = internet
    switch(type) {
        case "LinkedIn":
            finalImage = linkedin
            break;
        case "GitHub":
            finalImage = github
            break;
        case "Twitter":
            finalImage = twitter
            break;
        case "Instagram":
            finalImage = instagram
            break;
        default:
            break;
    }
    return finalImage;
}

function switchSocialLink(type) {
    switch(type) {
        case "LinkedIn":
            return "http://linkedin.com/in/"
        case "GitHub":
            return "https://github.com/"
        case "Twitter":
            return "https://twitter.com/"
        case "Instagram":
            return "https://www.instagram.com/"
        default:
            return ""
    }
}

const DetailCardComponent = () => {
    let location = useLocation();

    const {error, loading, data} = useQueryCard(location);

    return (
        <>
            <NavComponent />
            {loading ? <SpinnerComponent /> :
                (error ? <ErrorComponent /> :
                <>
                    <Helmet>
                        <title>{data.full_name ? `${data.full_name} - JinxVB` : 'Jinx' }</title>
                        <meta name="apple-itunes-app" content={`app-id=1533574525`}/>
                        {/* <meta name="apple-itunes-app" content={`app-id=962194608, app-argument=${data.share_link ? data.share_link : document.URL}`}/> */}
                    </Helmet>

                    <section className="card-layout">
                        <figure className="cover-img onload lazy-loaded"
                        data-delayed-background
                        style={{backgroundImage:`url('${data.card_picture_link ? data.card_picture_link : defaultCardImagePath}')`}}>
                        </figure>
                        <div className="header-card-layout_info">
                            <img className="header-card-layout_cont-img  deco-cont-img  deco-cont-img_circle   lazy-loaded" alt="Profile" src={data.profile_picture_link ? `url('${data.profile_picture_link}')` : defaultProfileImage} />
                            <div className="header-card-layout_cont-info">
                                {data.full_name && <h1 className="header-card-layout_title">{data.full_name}</h1>}
                                {data.position && <h2 className="header-card-layout_headline">{data.position}</h2>}
                            </div>
                        </div>
                    </section>

                    <section className="card-layout">
                        {data.company && <GeneralItemLabelComponent label="Company" value={data.company} /> }
                        {data.phones.map(phone => ( phone.phone_number &&
                            <GeneralItemLabelSublabelComponent key={phone.phone_type} label="Phone" sublabel={phone.phone_type} value={phone.phone_number} />
                        ))}
                        {data.email && <GeneralItemLinkComponent label="Email" value={data.email} link={`mailto: ${data.email}`} />}
                        
                        {/* <GeneralItemLinkComponent label="LinkedIn" value="nhkhanh" link="https://google.com" styleClass="general-card-layout-info_linkedin-item"/> */}
                    </section>


                    <section className="card-layout">
                        {data.socials.map(social => (social.social_value && 
                            <GeneralImageItemLinkComponent key={social.social_type} icon={switchSocialImage(social.social_type)} value={social.social_value} link={`${switchSocialLink(social.social_type)}${social.social_value}`} />))}
                        {data.web_page && <GeneralImageItemLinkComponent icon={internet} value={data.web_page} link={data.web_page}/>}
                    </section>

                    <section>
                        {data.quote && <img className="quote-section-layout_entity-image" src={quoteIcon} alt="quoteImg" />}
                        {data.quote && <p className="quote-section-layout_entity-message" >{data.quote}</p>}
                    </section>
                    
                    { (data.address.lat && data.address.lng) && 
                        <section className="card-layout card-layout_entity-map-cnt">
                            <MapsComponent lat={data.address.lat} lng={data.address.lng} />
                        </section>
                    }
                </>
                )
            }
        </>
    )
}

export default DetailCardComponent
