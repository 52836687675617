import React from 'react';
// Importing style
import './GeneralItemComponents.css';

export const GeneralItemLabelComponent = (props) => {
    const { label, value } = props;

    return(
        <div className="general-card-layout-info_entity-item-cont">
            <div className="general-card-layout-info_entity-item">
                <h2 data-testid="gen-item-label-comp_label" className="general-card-layout-info_label">{label}</h2>
            </div>
            <div className="general-card-layout-info_entity-item">
                <h2 data-testid="gen-item-label-comp_value" className="general-card-layout-info_value">{value}</h2>
            </div>
        </div>
    );
};

export const GeneralItemLabelSublabelComponent = (props) => {
    const { label, sublabel, value } = props;

    return(
        <div className="general-card-layout-info_entity-item-cont">
            <div className="general-card-layout-info_entity-item">
                <div className="general-card-layout-info_label-sublabel-cont">
                    <h2 data-testid="gen-item-label-sublabel-comp_label" className="general-card-layout-info_label">{label}</h2>
                    <h4 data-testid="gen-item-label-sublabel-comp_sub-label" className="general-card-layout-info_sub-label">{sublabel}</h4>
                </div>
            </div>
            <div className="general-card-layout-info_entity-item">
                <h2 data-testid="gen-item-label-sublabel-comp_value" className="general-card-layout-info_value">{value}</h2>
            </div>
        </div>
    );
};

export const GeneralItemLinkComponent = (props) => {
    const { styleClass , label, link, value } = props;

    return (
        <div className= {`general-card-layout-info_entity-item-cont ${styleClass}`}>
            <div className="general-card-layout-info_entity-item">
                <h2 data-testid="gen-item-link-comp_label" className="general-card-layout-info_label">{label}</h2>
            </div>
            <div className="general-card-layout-info_entity-item">
                <a data-testid="gen-item-link-comp_value-link" className="general-card-layout-info_value general-card-layout-info_entity-item-link" 
                href={link}
                target="_blank"
                rel="noopener noreferrer">{value}</a>
            </div>
        </div>
    );
};

export const GeneralImageItemLinkComponent = (props) => {
    const { icon, link, value } = props;

    return (
        <div className= {`general-card-layout-info_entity-item-cont`}>
            <div className="general-card-layout-info_entity-item">
                <img className="general-card-layout-info_social-icon" src={icon} alt="social img"/>
                {/* <h2 data-testid="gen-item-link-comp_label" className="general-card-layout-info_label">{label}</h2> */}
            </div>
            <div className="general-card-layout-info_entity-item">
                <a data-testid="gen-item-link-comp_value-link" className="general-card-layout-info_value general-card-layout-info_entity-item-link" 
                href={link}
                target="_blank"
                rel="noopener noreferrer">{value}</a>
            </div>
        </div>
    );
};