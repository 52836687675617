import React from 'react'
// Importing style
import './NavComponent.css';
// Importing image
import homelogo from '../../Assets/Logos/jinxvb.logo.red.png';

function NavComponent() {
    return (
        <header className="header pin-top-nav">
            <nav data-testid="nav-bar_test-id" className="nav" aria-label="Primary">
                <a data-testid="nav-bar_home-expand-logo" className="nav-logo-link" href="/">
                    <span className="sr-only">JinxVB</span>
                    <img className="nav-logo" src={homelogo} alt="JinxVB" />
                </a>

                {/* <button className="nav-bar_dynamic-open-app-btn" type="button" onClick={}>Open in app (test mode)</button> */}
            </nav>
        </header>
    )
}

export default NavComponent
