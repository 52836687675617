import React from 'react';

// Importing style
import './FooterComponent.css';
import '../../App.css';
// Importing image
import iosapp from '../../Assets/Socials/apple.label.png';

function FooterComponent() {
    // const displayToastMessage = () => {
    //     var element = document.getElementById("toast-bar");
    //     element.className = "pin-bottom show"
    //     // hide toast message after 3 seconds
    //     setTimeout(function() { element.className = element.className.replace("show",""); }, 3000)
    // }

    const openAppStore = () => {
        const sample_url = "https://apps.apple.com/us/app/jinxvb/id1533574525";
        window.open(sample_url, "_tab");
    }

    return (
        <footer className="main-footer-cnt" >
            <hr />
            <div className="general-card-layout-info_entity-item-cont">
                <div className="general-card-layout-info_entity-item">
                    <a className="general-link-entity" href="http://jinxvb.com/documents/privacy_policy">
                        <h4 className="general-card-layout-info_entity-item-link no-margin-cnt-entity" id="main-footer-cnt_copyright-header">Privacy Policy</h4>
                    </a>
                    <h4 id="main-footer-cnt_copyright-header">&copy; {new Date().getFullYear()} JinxVB</h4>
                </div>
                <div className="general-card-layout-info_entity-item">
                    <img data-testid="start-comp_app-store-image-link" id="appstore-header" className="main-footer-cnt_appstore-image" onClick={openAppStore} src={iosapp} alt="appstore"/>
                </div>
            </div>
            {/* <div className="pin-bottom" id="toast-bar">Coming soon to App store.</div> */}
        </footer>
    )
}

export default FooterComponent
